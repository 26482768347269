import firebase from "firebase/app";
import "firebase/auth";

let firebaseConfig = {
  apiKey: "AIzaSyAUtdfJCQ53rWXwpKOKVc_a2xgGws-8qbU",
    authDomain: "viajando-ando-775.firebaseapp.com",
    databaseURL: "https://viajando-ando-775.firebaseio.com",
    projectId: "viajando-ando-775",
    storageBucket: "viajando-ando-775.appspot.com",
    messagingSenderId: "842684794163",
    appId: "1:842684794163:web:04e8807844dd082168aa46"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// firebase.analytics();
